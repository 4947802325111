<template>
  <v-col>
    <v-row class="ma-3 justify-center">
      <v-card
        v-if="!organization"
        class="px-3 col-12 elevation-0"
      >
        <v-card-text class="error-message pa-3">
          <v-row>
            <v-col>
              <v-icon>error_outline</v-icon>

              <span class="header-2 pl-2">
                Not Found
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-else-if="isOurCompany"
        class="px-3 col-12 elevation-0"
      >
        <v-card-title>
          <v-col class="col-2">
            <v-avatar
              class="elevation-1"
            >
              <img
                :src="icons.organization"
                alt="Avatar"
                color="offwhite"
                size="48"
              >
            </v-avatar>
          </v-col>

          <v-col>
            <v-row class="col-12 header-2">
              OAO
            </v-row>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="title col-2">
              Profile
            </v-col>

            <v-col class="pl-2">
              <v-col class="py-2">
                <div class="font-weight-bold">
                  Headquarters
                </div>

                <div>
                  451 Broadway, 3rd Floor<br>
                  New York, NY 10013
                </div>
              </v-col>

              <v-col class="py-2">
                <div class="font-weight-bold">
                  California
                </div>

                <div>
                  22 Battery Street, #700<br>
                  San Francisco, CA 94111
                </div>
              </v-col>

              <v-col class="py-2">
                <div class="font-weight-bold">
                  Colorado
                </div>

                <div>
                  8700 Turnpike Drive, Suite 495<br>
                  Westminster, CO 80031
                </div>
              </v-col>

              <v-col class="py-2">
                <div class="font-weight-bold">
                  Illinois
                </div>

                <div>
                  350 North LaSalle Blvd, Suite 420<br>
                  Chicago, IL 60654
                </div>
              </v-col>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="title col-2">
              Metadata
            </v-col>

            <v-col class="pl-2">
              <v-col>
                Formed in

                <span class="font-weight-bold">
                  2001
                </span>

                by

                <span class="font-weight-bold">
                  Craig Leshen
                </span>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col v-else>
        <v-row>
          <v-col class="pt-0 col-auto">
            <span
              class="subtitle-2 primary--text cursor-pointer"
              @click="goToDashboard"
            >Organization</span>
          </v-col>

          <v-col class="pt-0 px-0 col-auto">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="gray--text"
              style="font-size: 9px; margin-bottom: 1px;"
            />
          </v-col>

          <v-col class="pt-0 col-auto">
            <span class="subtitle-2">{{ organization.orgName }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12">
            <v-card
              class="elevation-0"
            >
              <v-card-title>
                <v-row class="align-center">
                  <v-col>
                    <v-row class="justify-space-between">
                      <v-col>
                        <span
                          class="headline font-weight-bold"
                        >{{ organization.orgName || organization.text || 'Unknown' }}</span>
                      </v-col>

                      <v-col class="text-right">
                        <span class="subtitle-2 pr-2">{{ isActiveLabel }}</span>

                        <v-icon :color="isActiveColor">
                          {{ isActive ? 'check_circle' : 'cancel' }}
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="justify-space-around">
          <v-col class="pa-2 col-auto">
            <v-card
              class="elevation-0 pl-2"
              min-width="250px"
              max-width="320px"
              width="100%"
            >
              <v-card-title>
                <v-row class="align-center">
                  <v-col>
                    <v-row>
                      <v-col>
                        <span class="body-2 font-weight-bold">Impressions</span>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 headline font-weight-bold">
                      <v-col>
                        <span>{{ calculatedImpressions }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col class="pa-2 col-auto">
            <v-card
              class="elevation-0 pl-2"
              min-width="250px"
              max-width="320px"
              width="100%"
            >
              <v-card-title>
                <v-row class="align-center">
                  <v-col>
                    <v-row>
                      <v-col>
                        <span class="body-2 font-weight-bold">Clicks</span>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 headline font-weight-bold">
                      <v-col>
                        <span>{{ calculatedClicks }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col
            v-if="hasAccessToOrgTimeEntries"
            class="pa-2 col-auto"
          >
            <v-card
              class="elevation-0 pl-2"
              min-width="250px"
              max-width="320px"
              width="100%"
            >
              <v-card-title>
                <v-row class="align-center">
                  <v-col>
                    <v-row>
                      <v-col>
                        <span class="body-2 font-weight-bold">Billable Hours</span>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 headline font-weight-bold">
                      <v-col>
                        <span>{{ calculatedBillableHours }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col
            v-if="hasAccessToOrgTimeEntries"
            class="pa-2 col-auto"
          >
            <v-card
              class="elevation-0 pl-2"
              min-width="250px"
              max-width="320px"
              width="100%"
            >
              <v-card-title>
                <v-row class="align-center">
                  <v-col>
                    <v-row>
                      <v-col>
                        <span class="body-2 font-weight-bold">All Revenue (Beta)</span>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 headline font-weight-bold">
                      <v-col>
                        <span class="pr-1">$</span>
                        <span>{{ calculatedRevenue.replace('$', '') }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="px-3 pt-3 pb-6 elevation-0"
            >
              <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
                Identifiers
              </v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col
                    v-if="hasAccessToOrgTimeEntries"
                  >
                    <v-row class="subtitle-2">
                      <v-col>
                        <v-tooltip left>
                          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                            <div class="cursor-pointer midgray--text">
                              <span
                                v-bind="tooltipAttrs"
                                v-on="tooltipOn"
                              >
                                Client ID
                              </span>
                              <span
                                class="toasted--text"
                              >
                                *
                              </span>
                            </div>
                          </template>

                          <span>Admin Only</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ organization.clientId || 'None' }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Publisher ID
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ organization.publisherId || 'None' }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Billing Code
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ organization.billingCode || 'None' }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Network Code
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col
                        v-if="adServerUrl"
                        class="py-0 font-weight-medium nearblack--text"
                      >
                        <a
                          :href="adServerUrl"
                          target="_blank"
                        >
                          {{ organization.networkCode }}
                        </a>
                      </v-col>

                      <v-col
                        v-else
                        class="py-0 font-weight-medium nearblack--text"
                      >
                        None
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              class="px-3 pt-3 pb-6 elevation-0"
            >
              <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
                Service Data
              </v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Status
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ isActiveLabel }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Internal Use Only
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ isInternalUseOnly }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Timesheet-Enabled
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ hasTimesheetReport }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Reports
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-3 body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        <v-icon
                          class="pr-1"
                          :color="hasPacingReport.color"
                          :title="hasPacingReport.value"
                          small
                        >
                          {{ hasPacingReport.icon }}
                        </v-icon>

                        <a
                          v-if="hasPacingReport.value"
                          :href="`/delivery/pacing/${billingCode}`"
                        ><span class="outline">Pacing</span></a>

                        <span
                          v-else
                          class="outline"
                        >Pacing</span>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-3 body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        <v-icon
                          class="pr-1"
                          :color="hasDiscrepancyReport.color"
                          :title="hasDiscrepancyReport.value"
                          small
                        >
                          {{ hasDiscrepancyReport.icon }}
                        </v-icon>

                        <a
                          v-if="hasDiscrepancyReport.value"
                          :href="`/delivery/discrepancy/${billingCode}`"
                        >
                          <span class="outline">Discrepancy</span>
                        </a>

                        <span
                          v-else
                          class="outline"
                        >Discrepancy</span>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-3 body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        <v-icon
                          class="pr-1"
                          :color="hasHealthAdxReport.color"
                          :title="hasHealthAdxReport.value"
                          small
                        >
                          {{ hasHealthAdxReport.icon }}
                        </v-icon>

                        <a
                          v-if="hasHealthAdxReport.value"
                          :href="`/delivery/adx/${billingCode}`"
                        >
                          <span class="outline">AdX Health</span>
                        </a>

                        <span
                          v-else
                          class="outline"
                        >AdX Health</span>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-3 body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        <v-icon
                          class="pr-1"
                          :color="hasHealthNetworkReport.color"
                          :title="hasHealthNetworkReport.value"
                          small
                        >
                          {{ hasHealthNetworkReport.icon }}
                        </v-icon>

                        <a
                          v-if="hasHealthNetworkReport.value"
                          :href="`/delivery/network/${billingCode}`"
                        >

                          <span class="outline">Network Health</span>
                        </a>

                        <span
                          v-else
                          class="outline"
                        >Network Health</span>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-3 body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        <v-icon
                          class="pr-1"
                          :color="hasYieldPartnerReport.color"
                          :title="hasYieldPartnerReport.value"
                          small
                        >
                          {{ hasYieldPartnerReport.icon }}
                        </v-icon>

                        <a
                          v-if="hasYieldPartnerReport.value"
                          :href="`/delivery/yield/${billingCode}`"
                        >
                          <span class="outline">Yield Partner</span>
                        </a>

                        <span
                          v-else
                          class="outline"
                        >Yield Partner</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="mt-6">
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Teams
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="teams"
                      class="body-2 mt-0"
                    >
                      <v-col>
                        <v-data-table
                          :headers="teamColumns"
                          :items="teams"
                          dense
                          disable-pagination
                          hide-default-footer
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-else
                      class="body-2"
                    >
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        False
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              v-if="hasAccessToOrgTimeEntries"
              class="px-3 pt-3 pb-6 elevation-0"
            >
              <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
                <v-col>
                  <v-row>
                    <v-col>
                      <span>Time Entries</span>
                    </v-col>

                    <v-col class="col-auto text-right">
                      <v-row>
                        <v-col>
                          <!-- Export Button -->
                          <v-btn
                            color="primary"
                            dark
                            small
                            @click.stop="exportTimeEntriesAsCsv"
                          >
                            <font-awesome-icon
                              icon="['fas', 'file-export']"
                              size="sm"
                            />
                            <span>Export</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-subtitle>

              <v-card-text>
                <v-row class="px-2">
                  <v-col>
                    <v-row class="subtitle-2">
                      <v-col class="midgray--text">
                        Billable Hours
                      </v-col>
                    </v-row>
                    <v-row class="body-2 mt-0">
                      <v-col class="py-0 font-weight-medium nearblack--text">
                        {{ calculatedBillableHours }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="timeColumns"
                      :items="timeEntries"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              class="px-3 pt-3 pb-6 elevation-0"
            >
              <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
                Change Log
              </v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-row class="body-2">
                      <v-col class="py-0 font-weight-medium">
                        <span class="pr-1 midgray--text">Created on</span>

                        <span class="nearblack--text">
                          {{ createdOn }}
                        </span>

                        <span class="px-1 midgray--text">by</span>

                        <span class="nearblack--text">
                          {{ createdBy }}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="updatedBy"
                      class="body-2"
                    >
                      <v-col class="py-0 font-weight-medium">
                        <span class="pr-1 midgray--text">Updated on</span>

                        <span class="nearblack--text">
                          {{ updatedOn }}
                        </span>

                        <span class="px-1 midgray--text">by</span>

                        <span class="nearblack--text">
                          {{ updatedBy }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="px-3 pt-3 pb-6 elevation-0"
            >
              <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
                Data
              </v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col class="col-auto">
                    <pre class="lang-json">
                      <code class="lang-json">
                        {{ jsonRepresentation }}
                      </code>
                    </pre>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import 'prismjs'
import 'prismjs/components/prism-json'

import dates from 'src/utils/dates'
import format from 'src/utils/format'
import gam from 'src/utils/gam'

import PATHS from 'src/router/paths.js'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'OrganizationProfile',
  data() {
    return {
      lineItems: [],
      timeEntries: [],

      teamColumns: [
        {
          sortable: true,
          text: 'Team Name',
          value: 'teamName',
        },
        {
          sortable: true,
          text: 'Team ID',
          value: 'teamId',
        },
        {
          sortable: true,
          text: 'Team Code',
          value: 'teamCode',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters([
      'hasAccessToOrgTimeEntries',
      'userEmail',
    ]),
    ...mapLayoutState({
      icons: (state) => state.icons,
      valueIcons: (state) => state.icons.values,
    }),
    ...mapToolsState({
      columns: (state) => state.columns,
      // @todo Rename organization to selectedSearch for consistency.
      organization: (state) => state.selectedSearch,
      reportId: (state) => state.reportId,
      searchResults: (state) => state.searchResults,
    }),
    ...mapToolsGetters([
      'getReportKey',
    ]),

    breadcrumbs() {
      return [
        {
          text: 'Organizations',
          to: `/${PATHS.INTERNAL.BASE}/${PATHS.INTERNAL.ORGANIZATION}`,
        },
        {
          disabled: true,
          text: this.organization.orgName || this.organization.text || 'Unknown',
        },
      ]
    },

    timeColumns() {
      return [
        {
          align: 'start',
          columnName: 'Date',
          class: 'time-entry-date',
          key: 'timeEntryDate',
          columnValue: (item) => item.timeEntryDate,
          exportOnly: false,
          exportAs: ['date', 'L'],
          formatAs: 'date',
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 102,

          sortable: true,
          text: 'Date',
          value: 'timeEntryDateFormatted',
        },
        {
          align: 'start',
          columnName: 'Person',
          class: 'person-name',
          key: 'personName',
          columnValue: (item) => item.personName,
          exportOnly: false,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 120,

          sortable: true,
          text: 'Person',
          value: 'personName',
        },
        {
          align: 'start',
          columnName: 'Team',
          class: 'person-team-name',
          key: 'teamName',
          columnValue: (item) => item.teamName,
          exportOnly: false,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 120,

          sortable: true,
          text: 'Team',
          value: 'teamName',
        },
        {
          align: 'start',
          columnName: 'Time',
          class: 'billable-duration',
          key: 'billableActionDuration',
          columnValue: (item) => item.billableActionDuration,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 75,

          sortable: true,
          text: 'Duration (HH:MM)',
          value: 'durationFormatted',
        },
        {
          align: 'start',
          columnName: 'Time Type',
          class: 'time-type',
          key: 'timeClassificationName',
          columnValue: (item) => item.timeClassificationName,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 120,

          sortable: true,
          text: 'Classification',
          value: 'timeClassificationName',
        },
        {
          align: 'start',
          columnName: 'Project',
          class: 'project-name',
          key: 'projectName',
          columnValue: (item) => item.projectName,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 120,

          sortable: true,
          text: 'Project',
          value: 'projectName',
        },
        {
          align: 'start',
          columnName: 'Notes',
          class: 'work-note',
          key: 'workNote',
          columnValue: (item) => item.workNote,
          // sortable: true,
          // linkProperty: false,
          // isLinkExternal: false,
          // linkTitle: null,
          // width: 130,

          sortable: true,
          text: 'Note',
          value: 'workNote',
        },
      ]
    },

    isOurCompany() {
      const { reportId } = this
      return ['0', 'oao'].includes(reportId)
    },

    billingCode() {
      const { organization = {} } = this
      const { billingCode } = organization

      return billingCode
    },
    adServerUrl() {
      const { organization = {} } = this
      const { networkCode } = organization

      if (!networkCode) return false

      return gam.network(networkCode)
    },
    isDeleted() {
      const { organization = {} } = this
      const { isDeleted } = organization

      return isDeleted
    },
    isActive() {
      const { organization = {} } = this
      const { isActive } = organization

      return isActive
    },
    isActiveColor() {
      const { isActive, valueIcons } = this
      const status = String(isActive)
      const { color = 'nearblack' } = valueIcons[status] || {}

      return color
    },
    isActiveLabel() {
      if (this.isDeleted) return 'Deleted'
      return this.isActive ? 'Active' : 'Inactive'
    },
    hasPacingReport() {
      const { organization = {}, valueIcons } = this
      const { hasPacing: value, hasPacingReport = false } = organization
      const propertyName = String(hasPacingReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasDiscrepancyReport() {
      const { organization = {}, valueIcons } = this
      const { hasDiscrepancy: booleanValue, hasDiscrepancyReport = false } = organization
      const propertyName = String(hasDiscrepancyReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value: booleanValue }
    },
    hasHealthAdxReport() {
      const { organization = {}, valueIcons } = this
      const { hasHealthAdx: value, hasHealthAdxReport = false } = organization
      const propertyName = String(hasHealthAdxReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasHealthNetworkReport() {
      const { organization = {}, valueIcons } = this
      const { hasHealthNetwork: value, hasHealthNetworkReport = false } = organization
      const propertyName = String(hasHealthNetworkReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasYieldPartnerReport() {
      const { organization = {}, valueIcons } = this
      const { hasProgrammatic: value, hasYieldPartnerReport = false } = organization
      const propertyName = String(hasYieldPartnerReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasTimesheetReport() {
      const { organization = {} } = this
      const { hasTimesheetReport } = organization

      return hasTimesheetReport
    },
    isInternalUseOnly() {
      const { organization = {} } = this
      const { isInternalUseOnly } = organization

      return isInternalUseOnly
    },
    teams() {
      const { organization = {} } = this
      const { teams = [] } = organization

      return teams
    },
    createdBy() {
      const { organization = {} } = this
      const unknownActor = 'Unknown'
      const { assoc_email = unknownActor, updated_dt } = organization

      return !updated_dt ? assoc_email : unknownActor
    },
    createdOn() {
      const { organization = {} } = this
      const { created_dt } = organization

      return dates.getFormattedLocalDateTime(created_dt)
    },
    updatedBy() {
      const { organization = {} } = this
      const { assoc_email, updated_dt } = organization

      return updated_dt ? assoc_email : null
    },
    updatedOn() {
      const { organization = {} } = this
      const { updated_dt } = organization

      if (!updated_dt) return 'Date Unknown'

      return dates.getFormattedLocalDateTime(updated_dt)
    },
    calculatedBillableHours() {
      const { timeEntries } = this
      const minutesRaw = timeEntries.reduce((acc, item) => acc + item.billableActionDuration, 0)
      const hoursRaw = minutesRaw === 0 ? 0 : minutesRaw / 60
      const hours = hoursRaw.toFixed(2)

      const [wholeHours, minutesAsDecimalString] = hours.split('.')
      const minutesAsDecimal = Number(minutesAsDecimalString)

      const minutesMap = [
        () => {
          const isZero = minutesAsDecimal === 0
          const isGtThreeQuarters = minutesAsDecimal > 75

          return (isZero || isGtThreeQuarters) && ''
        },
        () => {
          const isGtZero = minutesAsDecimal > 0
          const isLteOneQuarter = minutesAsDecimal <= 25

          return isGtZero && isLteOneQuarter && ':15'
        },
        () => {
          const isGtOneQuarter = minutesAsDecimal > 25
          const isLteHalf = minutesAsDecimal <= 50

          return isGtOneQuarter && isLteHalf && ':30'
        },
        () => {
          const isGtHalf = minutesAsDecimal > 50
          const isLteThreeQuarters = minutesAsDecimal <= 75

          return isGtHalf && isLteThreeQuarters && ':45'
        },
      ]

      const minutes = minutesMap.reduce((foundMatch, findMatch) => foundMatch || findMatch() || '', '')

      return `${this.formatNumber(wholeHours)}${minutes}`
    },
    calculatedClicks() {
      const { lineItems } = this
      const result = lineItems.reduce((acc, item) => acc + item.clicks, 0)

      return this.formatNumber(result)
    },
    calculatedImpressions() {
      const { lineItems } = this
      const result = lineItems.reduce((acc, item) => acc + item.impressions, 0)

      return this.formatNumber(result)
    },
    calculatedRevenue() {
      const { lineItems } = this
      const result = lineItems.reduce((acc, item) => {
        if (item.allRevenue !== -1) {
          acc += item.allRevenue
        }

        return acc
      }, 0)

      return format.microCurrency({
        currencyCode: 'USD',
        microAmount: result,
      })
    },
    jsonRepresentation() {
      return this.organization
    },
  },
  watch: {
    organization: {
      deep: true,
      handler(newValue, oldValue) {
        const haveNewValue = newValue && newValue.publisherId
        const hasChanged = !oldValue || (newValue.publisherId !== oldValue.publisherId)

        if (haveNewValue && hasChanged) {
          this.setTimeEntries()
          this.setLineItems()
        } else if (!newValue) {
          this.timeEntries = []
          this.lineItems = []
        }
      },
    },
  },
  created() {
    if (this.organization && this.organization.publisherId) {
      this.setTimeEntries()
      this.setLineItems()
    }
  },
  methods: {
    formatNumber(number) {
      return format.number(number)
    },

    async setTimeEntries() {
      const { organization } = this

      // @assert If the user is an internal admin, they can see all time entries.
      // @assert If the user is not an internal admin, they can only see their own time entries.

      // Fetch time entries from the API.
      const timeEntriesResponse = await this.$store.dispatch('tools/fetchTimeEntriesForPublisher', organization)
      const timeEntries = timeEntriesResponse?.content

      // Save the time entries to the store.
      if (timeEntries) {
        this.timeEntries = timeEntries
      } else {
        this.timeEntries = []
      }
    },
    async setLineItems() {
      // Fetch line items from the API.
      const params = {
        publisherId: this.organization.publisherId,
      }

      const lineItemsResponse = await this.$store.dispatch('tools/fetchLineItemsForPublisher', params)
      const lineItems = lineItemsResponse?.content

      // Save the line items to the store.
      if (lineItems) {
        this.lineItems = lineItems
      } else {
        this.lineItems = []
      }
    },

    async findSelectedSearch(nav) {
      const { params, route: routeName } = nav
      const { searchResults } = this
      const { reportName, reportPath, reportId, subId } = params
      const reportKey = this.getReportKey({ reportName, reportPath, routeName })

      const searchEndpoint = searchResults.endpoints[reportKey]
      const searchCollection = await searchEndpoint.items.list

      return this.$store.dispatch('tools/findSelectedSearch', { reportId, reportName, searchCollection, subId })
    },
    async selectSearchItem(nav) {
      const selection = await this.findSelectedSearch(nav)

      await this.$store.dispatch('tools/selectSearchItem', selection)

      return this.$store.dispatch('tools/renderMainContent', this.$route)
    },
    goToDashboard() {
      const { reportName } = this.organization
      const reportId = undefined
      const subId = undefined
      const nav = { params: { reportName, reportId, subId } }

      return this.selectSearchItem(nav)
    },

    exportTimeEntriesAsCsv() {
      const {
        timeColumns: exportColumns,
        timeEntries,
        reportName: exportName,
      } = this

      const exportFormat = 'csv'

      const exportGroups = [
        {
          items: timeEntries,
        },
      ]

      return this.$store.dispatch('tools/exportAs', { exportColumns, exportFormat, exportGroups, exportName })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__subtitle {
  letter-spacing: 0.025rem;
}
</style>
